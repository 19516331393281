import React, { useState, useEffect } from 'react'; 
import Question from './utils/question';
import './App.css';
import { v4 as uuidv4 } from 'uuid';
import coach_image from "./assets/3443bc01-13d4-4eb4-9863-950e7e3dbb7a.png";

function App() {
  // State to store the session ID
  const [sessionId, setSessionId] = useState('');

  useEffect(() => {
    // Function to generate a unique session ID
    const generateSessionId = () => {
      return uuidv4();
    };

    // Check if a session ID is already set in local storage
    const storedSessionId = localStorage.getItem('session_id');

    if (storedSessionId) {
      // If a session ID is stored, use it
      setSessionId(storedSessionId);
    } else {
      // Otherwise, generate a new session ID
      const newSessionId = generateSessionId();
      setSessionId(newSessionId);

      // Store the new session ID in local storage for future use
      localStorage.setItem('session_id', newSessionId);
    }
  }, []);


  return (
    <div className="App max-w-2xl mx-auto">
      <h1 className="text-3xl font-bold my-3 px-2 notranslate"> 
        SMILE Coach
      </h1>
      <div className="flex justify-left px-2 items-center">
        <img src={coach_image} alt="SMILE Coach as a robot" />
      </div>
      <p className="my-3 px-2 text-gray-600 text-lg">Fill out the information for the SMILE Coach to get to know you. After that, press <em>Ask <span className="notranslate"> SMILE Coach </span></em> to get your personal coaching recommendations.</p>
      <Question sessionId={sessionId} />
    </div>
  );
}

export default App;
