import React, { useState, useEffect } from 'react';
import { collection, doc, updateDoc, addDoc, serverTimestamp } from "firebase/firestore"; 
import { db, functions } from "./firebase";
import { httpsCallable } from "firebase/functions";

function Question({ sessionId }) {
  const [selectedOption, setSelectedOption] = useState(1);

  // List of options preloaded that students can select
  const options = [
    {
      label: "No Formal Education",
      question: "I haven't received any formal education. What are some potential opportunities or paths I can explore for personal and career development?",
      enjoy: "exploring new places",
      love: "meeting people from diverse backgrounds",
      bored: "listening to stories",
      notGood: "using technology",
      dislike: "heavy manual labor",
      occupation: "travel blogger",
      reason: "share my travel experiences",
      skills: "public speaking and photography"
    },
    {
      label: "Elementary Equivalent",
      question: "As someone with education equivalent to elementary school, what skills or areas should I focus on for future growth and employment?",
      enjoy: "playing outdoor games",
      love: "helping others in my community",
      bored: "coloring and drawing",
      notGood: "complex problem-solving",
      dislike: "sitting for long periods",
      occupation: "community volunteer",
      reason: "make my neighborhood a better place",
      skills: "teamwork and creativity"
    },
    {
      label: "Middle School Student",
      question: "Considering my current status as a middle school student, could you advise me on what major I should pursue in college and what kind of job would be suitable for me in the future?",
      enjoy: "building LEGO structures",
      love: "stargazing and identifying constellations",
      bored: "reading adventure books",
      notGood: "history lessons",
      dislike: "math and science",
      occupation: "astronomer",
      reason: "explore the mysteries of the universe",
      skills: "telescope operation and data analysis"
    },
    {
      label: "High School Graduate",
      question: "Considering my current status as a high school graduate, could you advise me on what I should learn and what kind of job would be suitable for me in the future?",
      enjoy: "designing fashion outfits",
      love: "organizing charity events",
      bored: "classical music",
      notGood: "computer programming",
      dislike: "public speaking",
      occupation: "fashion designer",
      reason: "create unique clothing for a cause",
      skills: "sewing and event planning"
    },
    {
      label: "College Student",
      question: "Considering my current status as a college student, could you advise me on what major I should pursue in graduate school and what kind of job would be suitable for me in the future?",
      enjoy: "coding mobile apps",
      love: "volunteering for environmental causes",
      bored: "watching science documentaries",
      notGood: "writing poetry",
      dislike: "accounting",
      occupation: "software engineer",
      reason: "develop eco-friendly tech solutions",
      skills: "programming and environmental advocacy"
    },
    {
      label: "Master's Degree Holder",
      question: "Having completed a master's degree, what specialization or industry should I consider for my career, and what advanced roles might be suitable for me?",
      enjoy: "conducting market research",
      love: "mentoring aspiring professionals",
      bored: "classical literature",
      notGood: "public speaking",
      dislike: "bureaucratic tasks",
      occupation: "management consultant",
      reason: "guide businesses toward success",
      skills: "data analysis and leadership"
    },
    {
      label: "Doctoral Candidate",
      question: "As a doctoral candidate, what are some research areas or industries where my expertise could be valuable, and what career options should I explore post-graduation?",
      enjoy: "analyzing complex data sets",
      love: "presenting research findings at conferences",
      bored: "pop culture trivia",
      notGood: "public relations",
      dislike: "routine administrative work",
      occupation: "research scientist",
      reason: "advance knowledge in my field",
      skills: "data analysis and academic writing"
    },
    {
      label: "Postdoctoral Fellow",
      question: "In my postdoctoral fellowship, how can I leverage my research skills and experience to contribute to academia, industry, or society, and what future opportunities should I prepare for?",
      enjoy: "collaborating on interdisciplinary projects",
      love: "mentoring the next generation of scientists",
      bored: "celebrity gossip",
      notGood: "corporate finance",
      dislike: "bureaucracy",
      occupation: "university professor",
      reason: "inspire and educate future leaders",
      skills: "research expertise and teaching"
    }
  ];

  const handleChangeOption = (e) => {
    setSelectedOption(e.target.value);
  };

  // Sample self-regulation statements
  const selfRegulationStatements = [
    "I struggle to control my impulses and urges.",
    "I have some difficulty managing my time and setting goals.",
    "I am fairly good at staying composed in stressful situations.",
    "I am usually able to control my thoughts and emotions effectively.",
    "I excel in maintaining self-control and managing my behavior in all circumstances.",
  ];

  const introversionExtroversionStatements = [
    "I prefer spending most of my time alone and find socializing draining.",
    "I enjoy socializing, but I also need plenty of alone time to recharge.",
    "I am comfortable with both socializing and spending time alone.",
    "I thrive in social situations and seek out opportunities to connect with others.",
    "I am always looking for social interactions, and I find them energizing."
  ];

  
  // Function to update self-regulation and toggle the statement
  const handleSelfRegulationChange = (e) => {
    const value = parseInt(e.target.value);
    setSelfRegulation(value);
  };

  const [enjoy, setEnjoy] = useState('');
  const [love, setLove] = useState('');
  const [bored, setBored] = useState('');
  const [notGood, setNotGood] = useState('');
  const [dislike, setDislike] = useState('');
  const [occupation, setOccupation] = useState('');
  const [reason, setReason] = useState('');
  const [skills, setSkills] = useState('');
  const [age, setAge] = useState('');
  const [currentOccupation, setCurrentOccupation] = useState('');
  const [selfRegulation, setSelfRegulation] = useState(3);
  const [introversionExtroversion, setIntroversionExtroversion] = useState(3);
  const [degreeSpecialization, setDegreeSpecialization] = useState('');
  const [formIncomplete, setFormIncomplete] = useState(false);
  const [pageLoadTimestamp, setPageLoadTimestamp] = useState(null);
  const [rating, setRating] = useState(null);
  const [docRefId, setDocRefId] = useState(null);
  const [responseGPT, setResponseGPT] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [formNotSubmitted, setFormNotSubmitted] = useState(true);
  const [countdown, setCountdown] = useState(30);
  const [countdownEllipsis, setCountdownEllipsis] = useState('.');

  // Function to handle star rating click
  const handleStarClick = async (selectedRating) => {
    try {
      const timestampFeedbackRating = new Date().toISOString();

      // Update the document in Firestore
      const submissionDocRef = doc(db, 'submissions', docRefId); // Replace 'docRefId' with the actual document ID
      await updateDoc(submissionDocRef, {
        feedbackRating: selectedRating,
        timestampFeedbackRating: timestampFeedbackRating,
      });

      // Update the local state with the new rating and timestamps
      setRating(selectedRating);

      console.log('Document updated with new rating and timestamps.');
    } catch (error) {
      console.error('Error updating document:', error);
    }
  };

  useState(() => {
    const timestamp = new Date().toISOString();
    setPageLoadTimestamp(timestamp);
  }, []);

  useEffect(() => {
    // If testing set default state for quick debugging
    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
      setEnjoy('playing outdoor games');
      setLove('helping others in my community');
      setBored('coloring and drawing');
      setNotGood('complex problem-solving');
      setDislike('sitting for long periods');
      setOccupation('community advocate');
      setReason('making neighborhood a better place');
      setSkills('teamwork and creativity');
      setAge(22);
    }

    let countdownInterval;
    let ellipsisInterval;

    if (isGenerating) {
      if (countdown === 30) {
        scrollWindowDown();
      }

      countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      ellipsisInterval = setInterval(() => {
        setCountdownEllipsis(() => {
          if (countdown % 3 === 1) {
            return '...';
          } else if (countdown % 3 === 2) {
            return '..';
          } else {
            return '.';
          }
        });
      }, 333);
    }

    // End condition for the countown
    if (countdown === 1) {
      clearInterval(countdownInterval);
      setCountdown(10);
    }
    if (countdown === 0 || !isGenerating) {
      clearInterval(countdownInterval);
      clearInterval(ellipsisInterval);
      setCountdownEllipsis('.');
    }

    return () => {
      clearInterval(countdownInterval);
      clearInterval(ellipsisInterval);
    };
  }, [countdown, isGenerating]);

  const scrollWindowDown = () => {
    window.scrollTo({
      top: document.body.scrollHeight, // Scroll to the bottom of the page
      behavior: 'smooth', // You can use 'auto' for an instant scroll or 'smooth' for a smooth scroll animation
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Get the current timestamp when the button is clicked
    const timestampButtonClick = new Date().toISOString();
    
    // Check if any of the variables is empty
    if (enjoy.trim() === '' ||
        love.trim() === '' ||
        bored.trim() === '' ||
        notGood.trim() === '' ||
        dislike.trim() === '' ||
        occupation.trim() === '' ||
        reason.trim() === '' ||
        skills.trim() === '') 
    {
      // Set formIncomplete to true if any variable is empty
      setFormIncomplete(true);

      // Set a timeout to remove the message after 5 seconds (5000 milliseconds)
      setTimeout(() => {
        setFormIncomplete(false);
      }, 5000); // 5000 milliseconds = 5 seconds
    } else {
      setFormIncomplete(false);
      setFormNotSubmitted(false);

      // Set 30-second countdown timer
      setIsGenerating(true);

      try {
        // Add a new document with the form data
        const docRef = await addDoc(collection(db, "submissions"), {
          inputSelectedOptionLabel: options[selectedOption].label,
          inputSelectedOptionQuestion: options[selectedOption].question,
          inputSelectedOption: selectedOption,
          inputEnjoy: enjoy,
          inputLove: love,
          inputBored: bored,
          inputNotGood: notGood,
          inputDislike: dislike,
          inputOccupation: occupation,
          inputReason: reason,
          inputSkills: skills,
          inputAge: age,
          inputCurrentOccupation: currentOccupation,
          inputSelfRegulation: selfRegulation,
          inputIntroversionExtroversion: introversionExtroversion,
          inputDegreeSpecialization: degreeSpecialization,
          timestampServerSubmission: serverTimestamp(),
          localStorageSessionId: sessionId,
          timestampPageLoad: pageLoadTimestamp,
          timestampButtonClick: timestampButtonClick,
          timestampFeedbackRating: null,
          feedbackRating: null,
          feedbackGPT: null
        });
        console.log("Document written with ID:", docRef.id);
        setDocRefId(docRef.id);

        // Construct the data to send to the external API
        const requestData = {
          selectedOptionLabel: options[selectedOption].label,
          selectedOptionQuestion: options[selectedOption].question,
          selectedOption: selectedOption,
          enjoy: enjoy,
          love: love,
          bored: bored,
          notGood: notGood,
          dislike: dislike,
          occupation: occupation,
          reason: reason,
          skills: skills,
          age: age,
          currentOccupation: currentOccupation,
          selfRegulation: selfRegulation,
          introversionExtroversion: introversionExtroversion,
          degreeSpecialization: degreeSpecialization,
        };

        // Firebase Functions httpsCallable
        const coachGPT = httpsCallable(functions, 'coachGPT');
        coachGPT(requestData)
          .then((result) => {
            // Read result of the Cloud Function.
            // const data = result.data;
            // const sanitizedMessage = data.text;
            // console.log('API response data:', result);
            let responseData = result.data;

            // Save this to firestore
            try {
              const submissionDocRef = doc(db, 'submissions', docRef.id); // Replace 'docRefId' with the actual document ID
              updateDoc(submissionDocRef, {
                feedbackGPT: responseData,
                timestampServerGPT: serverTimestamp(),
              });

              // Send the responses to browser
              setResponseGPT(responseData[0].message.content);
              setIsGenerating(false);
          
              console.log('Document updated with GPT response and timestamps.');
            } catch (error) {
              setFormNotSubmitted(true);
              // console.error('Error updating document:', error);
            }

            setIsGenerating(false);
            alert('SMILE Coach response generated.');

          }).catch((error) => {
            // Getting the Error details.
            // const code = error.code;
            // const message = error.message;
            // const details = error.details;
            // console.log(error)
            setIsGenerating(false);
            setFormNotSubmitted(true);
            alert('An error occurred with SMILE Coach. Please try again!');
          });

      } catch (error) {
        setIsGenerating(false);
        setFormNotSubmitted(true);

        console.error('Error submitting form:', error);
        alert('An error occurred while submitting the form.');
      }
    }
  };

  return (
    <div className="px-2">
      {/* Left column for input fields */}

      <form onSubmit={handleSubmit}>

        <div className="relative">
          <h2 className="text-xl font-bold mb-2 relative z-10 pb-3">Information about you</h2>
          <div className="h-1 w-full absolute bottom-0 left-0 bg-gradient-to-r from-red-200 to-red-000"></div>
        </div>

        <div className="mb-4">
          <label className="block mb-2" htmlFor="career-option">
            Your education level
          </label>
          <div className="relative">
            <select
              id="career-option"
              className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:ring focus:ring-indigo-300 focus:ring-opacity-50"
              onChange={handleChangeOption}
              value={selectedOption}
            >
              {options.map((option, index) => (
                <option key={index} value={index}>
                  {option.label}
                </option>
              ))}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-4 flex items-center px-2 text-gray-700">
              <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M10 12.293l-4.293-4.293a1 1 0 011.414-1.414L10 9.586l3.293-3.293a1 1 0 111.414 1.414L11.414 12.293a1 1 0 01-1.414 0z" />
              </svg>
            </div>
          </div>
        </div>

        {selectedOption >= 4 && ( // Check if education level is higher than College (Master or Ph.D.)
          <div>
            <label htmlFor="degreeSpecialization">Share your degree specialization</label>
            <input
              type="text"
              placeholder={options[selectedOption].degreeSpecialization}
              id="degreeSpecialization"
              onChange={(e) => setDegreeSpecialization(e.target.value)}
              className="w-full border p-2 mb-2"
            />
          </div>
        )}

        <label htmlFor="enjoy">Share activities that you enjoy</label>
        <input
          type="text"
          placeholder={options[selectedOption].enjoy}
          id="enjoy"
          value={enjoy}
          onChange={(e) => setEnjoy(e.target.value)}
          className="w-full border p-2 mb-2"
        />
        <label htmlFor="love">Share activities that you love</label>
        <input
          type="text"
          placeholder={options[selectedOption].love}
          id="love"
          value={love}
          onChange={(e) => setLove(e.target.value)}
          className="w-full border p-2 mb-2"
        />
        <label htmlFor="bored">Share something you can do for a long time without getting bored</label>
        <input
          type="text"
          placeholder={options[selectedOption].bored}
          id="bored"
          value={bored}
          onChange={(e) => setBored(e.target.value)}
          className="w-full border p-2 mb-2"
        />
        <label htmlFor="notgood">Share something you are not good at</label>
        <input
          type="text"
          placeholder={options[selectedOption].notGood}
          id="notgood"
          value={notGood}
          onChange={(e) => setNotGood(e.target.value)}
          className="w-full border p-2 mb-2"
        />
        <label htmlFor="dislike">Share subjects you dislike</label>
        <input
          type="text"
          placeholder={options[selectedOption].dislike}
          id="dislike"
          value={dislike}
          onChange={(e) => setDislike(e.target.value)}
          className="w-full border p-2 mb-2"
        />
        <label htmlFor="occupation">Share your desired occupation</label>
        <input
          type="text"
          placeholder={options[selectedOption].occupation}
          id="occupation"
          value={occupation}
          onChange={(e) => setOccupation(e.target.value)}
          className="w-full border p-2 mb-2"
        />

        <label htmlFor="reason">Share your reason for aspiration to become a {occupation}</label>
        <input
          type="text"
          placeholder={options[selectedOption].reason}
          id="reason"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          className="w-full border p-2 mb-2"
        />

        <label htmlFor="skills">Share skills you have</label>
        <input
          type="text"
          placeholder={options[selectedOption].skills}
          id="skills"
          value={skills}
          onChange={(e) => setSkills(e.target.value)}
          className="w-full border p-2 mb-2"
        />

        <label htmlFor="age">Share your age</label>
        <input
          type="text"
          placeholder={options[selectedOption].age}
          id="age"
          value={age}
          onChange={(e) => setAge(e.target.value)}
          className="w-full border p-2 mb-2"
        />

        <label htmlFor="currentOccupation">Share your current occupation (if already working)</label>
        <input
          type="text"
          placeholder={options[selectedOption].currentOccupation}
          id="currentOccupation"
          value={currentOccupation}
          onChange={(e) => setCurrentOccupation(e.target.value)}
          className="w-full border p-2 mb-2"
        />

        <label htmlFor="selfRegulation">Rate your self-regulation: <em>{selfRegulationStatements[selfRegulation - 1]}</em></label>
        <p></p>
        <input
          type="range"
          placeholder={options[selectedOption].selfRegulation}
          id="selfRegulation"
          min="1"
          max="5"
          step="1"
          value={selfRegulation}
          onChange={handleSelfRegulationChange}
          className="w-full border p-2 mb-2"
        />

        <label htmlFor="introversionExtroversion">Rate your introversion-extroversion: <em>{introversionExtroversionStatements[introversionExtroversion - 1]}</em></label>
        <input
          type="range"
          placeholder={options[selectedOption].introversionExtroversion}
          id="introversionExtroversion"
          min="1"
          max="5"
          step="1"
          value={introversionExtroversion}
          onChange={(e) => setIntroversionExtroversion(e.target.value)}
          className="w-full border p-2 mb-2"
        />

        <div className="relative">
          <h2 className="text-xl font-bold mb-2 mt-6 relative z-10 pb-3">Your story</h2>
          <div className="h-1 w-full absolute bottom-0 left-0 bg-gradient-to-r from-red-200 to-red-000"></div>
        </div>

        <p>
          I enjoy <strong className="notranslate">{enjoy || "{ activities that you enjoy }"}</strong>. I love <strong  className="notranslate">{love || "{ activities that you love }"}</strong>. I can <strong  className="notranslate">{bored || "{ something you can do for a long time without getting bored }"}</strong> for a long time without getting bored. I'm not good at <strong  className="notranslate">{notGood || "{ something you are not good at }"}</strong>. I dislike <strong  className="notranslate">{dislike || "{ subjects you dislike }"}</strong>. I aspire to become a <strong  className="notranslate">{occupation || "{ your desired occupation }"}</strong> to <strong  className="notranslate">{reason || "{ your reason for aspiration }"}</strong>. I have skills in <strong  className="notranslate">{skills || "{ skills you have }"}</strong>.
        </p>

        {/* Display a message if the form is incomplete */}
        {formIncomplete && (
          <p className="border-l-4 border-red-800 p-4 bg-red-100 mt-3">Please fill in all empty fields.</p>
        )}

        {formNotSubmitted && (
        <button type="submit" className="mt-4 mb-6 bg-red-100 px-6 py-3 text-xl font-bold rounded-lg">Ask <span className="notranslate"> SMILE Coach</span></button>
        )}
      </form>

      {isGenerating && (
      <div>
        <div className="relative">
          <h2 className="text-xl font-bold mb-2 mt-6 relative z-10">Feedback from <span className="notranslate">SMILE Coach</span></h2>
        </div>
        <div className="border-l-4 border-green-200 bg-green-100 bg-opacity-50 p-2 pl-3 mb-5">
          Generating response in approximately {countdown} seconds{countdownEllipsis}
        </div>
      </div>
      )}

      {responseGPT !== null && (
      <div>
        <div className="relative">
          <h2 className="text-xl font-bold mb-2 mt-6 relative z-10">Feedback from <span className="notranslate">SMILE Coach</span></h2>
        </div>
        <div className="border-l-4 border-green-200 bg-green-100 bg-opacity-50 p-2 pl-3 pb-1">
          {responseGPT.split('\n').map((line, index) => (<p className="mb-3" key={index}>{line}</p>))}
        </div>

        <div className="relative">
          <h2 className="text-xl font-bold mb-2 mt-6 relative z-10 pb-3">What do you think?</h2>
          <div className="h-1 w-full absolute bottom-0 left-0 bg-gradient-to-r from-red-200 to-red-000"></div>
        </div>
        <p>How would you rate the feedback you received from SMILE Coach?</p>

        {/* Star rating options */}
        <div className="mt-2 mb-4">
          {[1, 2, 3, 4, 5].map((star) => (
            <span
              key={star}
              className={`cursor-pointer text-3xl ${
                rating && star <= rating ? 'text-yellow-500' : 'text-gray-300'
              }`}
              onClick={() => handleStarClick(star)}
            >
              ★
            </span>
          ))}
        </div>
      </div>
      )}
    </div>
  );
}

export default Question;
