// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDPFK6G4tuT8DEjSwpJV-AaBPPbxxH8dPo",
  authDomain: "smile-coach.firebaseapp.com",
  projectId: "smile-coach",
  storageBucket: "smile-coach.appspot.com",
  messagingSenderId: "605993974848",
  appId: "1:605993974848:web:b0dac9d67535b2027173a5",
  measurementId: "G-B6MV9VTPT2"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const functions = getFunctions(app);

// Connect to localhost if using localhost
if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
  connectFirestoreEmulator(db, '127.0.0.1', 8080);
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
}

export { app, analytics, db, functions };